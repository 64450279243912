import SXAjax, {
    createAjaxHoc
} from '@/library/ajax';
import mockUrls from '../mock/url-config';
import handleError from './handle-error';
import handleSuccess from './handle-success';
import {
    getDefaultHost
} from '../pages/url/url';
import { toLogin } from './index';
const host = getDefaultHost();

/**
 * 判断请求是否是mock
 * @param url
 * @returns {boolean|*}
 */
export function isMock(url /* url, data, method, options */ ) {
    return mockUrls.indexOf(url) > -1 || url.startsWith('/mock');
}

/**
 * ajax工具，含有errorTip 和 successTip
 * @type {SXAjax}
 */
export const sxAjax = new SXAjax({
    onShowErrorTip: (error, errorTip) => handleError({
        error,
        errorTip
    }),
    onShowSuccessTip: (response, successTip) => handleSuccess({
        successTip
    }),
    isMock,
});

// 默认配置
sxAjax.defaults.baseURL = host;
sxAjax.defaults.timeout = 1000 * 60;
sxAjax.mockDefaults.baseURL = '/users';

/**
 * ajax高阶组件
 */
export const ajaxHoc = createAjaxHoc(sxAjax);

/**
 * ajax工具，不含有 errorTip和successTip
 * @type {SXAjax}
 */
export const ajax = new SXAjax({
    isMock,
});

// 默认配置
ajax.defaults.baseURL = host;
ajax.defaults.timeout = 1000 * 5;

// 请求前拦截
// [ajax.instance, sxAjax.instance].forEach(instance => {
//     instance.interceptors.request.use(cfg => {
//         // Do something before request is sent
//         const token = localStorage.getItem('token');
//         if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
//             cfg.headers.auth = token //请求头加上token
//         }
//         return cfg;
//     }, error => {
//         // Do something with request error
//         return Promise.reject(error);
//     });
//     instance.interceptors.response.use(res => {
//         // 对响应数据做些什么
//         if(res.data.Success){
//             return res;
//         }else{
//             if(res.data.Error==="登录失败!" || res.data.Error === "授权验证错误" || res.data.Error === "用户登录超时，请重新登录!" || res.data.Error === "parentCode:行政机构编号不存在!"){
//                setTimeout(()=>{
//                 toLogin()
//                },1000);
//             }
//             return res;
//         }
//       }, error => {
//         return Promise.reject(error)
//       })
// });
// 请求前拦截
[ajax.instance, sxAjax.instance].forEach(instance => {
    instance.interceptors.request.use(cfg => {
        // Do something before request is sent
        return cfg;
    }, error => {
        // Do something with request error
        return Promise.reject(error);
    });
        instance.interceptors.response.use(res => {
        // 对响应数据做些什么
        if(res.data.status===0){
            return res;
        }else{
            if(res.data.Error==="401"){
               setTimeout(()=>{
                toLogin()
               },1000);
            }
            if(res.data.status===1){
                return Promise.reject(res)
            }
        }
      }, error => {
        return Promise.reject(error)
      })
});

/**
 * mockjs使用的axios实例
 */
export const mockInstance = ajax.mockInstance = sxAjax.mockInstance;
