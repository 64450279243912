import React, { Component } from "react";
import { Form, message, notification } from "antd";
import config from "@/commons/config-hoc";
import { FormElement, ModalContent } from "@/library/components";
import PageContent from "@/layouts/page-content";
import validator from "@/library/utils/validation-rule";
import md5 from "@/pages/utils/md5";

@config({
    ajax: true,
    connect: (state) => ({ loginUser: state.system.loginUser }),
    modal: {
        title: "修改密码",
        width: 420,
    },
})
@Form.create()
export default class ModifyPassword extends Component {
    state = {
        loading: false,
    };

    handleOk = (e) => {
        e.preventDefault();

        const { loading } = this.state;
        if (loading) return;

        const {
            onOk,
            form: { validateFieldsAndScroll },
        } = this.props;

        validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log(values);
                if (values.new_password2 !== values.new_password) {
                    message.error("新密码不一致");
                    return 
                }
                values.old_password = md5(values.old_password, 32);
                values.new_password = md5(values.new_password, 32);
                this.setState({ loading: true });
                this.props.ajax
                    .post(`/doctor/updatePwd`, values, {})
                    .then((res) => {
                        notification.success({
                            message: "成功",
                            description: "修改密码成功",
                            duration: 2,
                        });
                        if (onOk) onOk();
                    })
                    .finally(() => this.setState({ loading: false }));
            }
        });
    };

    handleCancel = () => {
        const { onCancel } = this.props;
        if (onCancel) onCancel();
    };

    render() {
        const { loginUser, form } = this.props;
        const id = loginUser?.id;
        const { loading } = this.state;
        const labelWidth = 100;

        return (
            <ModalContent
                loading={loading}
                surplusSpace={false}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
            >
                <PageContent>
                    <Form>
                        <FormElement
                            form={form}
                            type="hidden"
                            field="id"
                            decorator={{ initialValue: id }}
                        />
                        <FormElement
                            form={form}
                            label="原密码"
                            labelWidth={labelWidth}
                            type="password"
                            field="old_password"
                            required
                        />
                        <FormElement
                            form={form}
                            label="新密码"
                            labelWidth={labelWidth}
                            type="password"
                            field="new_password"
                            required
                        />
                        <FormElement
                            form={form}
                            label="再次输入新密码"
                            labelWidth={labelWidth}
                            type="password"
                            field="new_password2"
                            required
                        />
                    </Form>
                </PageContent>
            </ModalContent>
        );
    }
}
