import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table } from "antd";
import { Pagination } from "@/library/components";
import { getElementTop } from "@/library/utils";
import { Resizable } from "react-resizable";
import "./index.less";
const ResizeableTitle = (props) => {
    const { onResize, width, onClick, ...restProps } = props;

    let resizing = false;
    if (!width) {
        return <th {...restProps} />;
    }
    return (
        <Resizable
            width={width}
            height={0}
            onResizeStart={() => (resizing = true)}
            onResizeStop={() => {
                resizing = true;
                setTimeout(() => {
                    resizing = false;
                }, 100);
            }}
            onResize={onResize}
            draggableOpts={{ enableUserSelectHack: false }}
        >
            <th
                onClick={(...args) => {
                    if (!resizing && onClick) {
                        onClick(...args);
                    }
                }}
                {...restProps}
            />
        </Resizable>
    );
};
export default class TableComponent extends Component {
    static propTypes = {
        surplusSpace: PropTypes.bool, // 是否使用剩余空间，如果 true 表格将铺满全屏
        serialNumber: PropTypes.bool, // 是否显示序号
        serialText: PropTypes.string,
        otherHeight: PropTypes.number,
        pagination: PropTypes.bool,
    };

    static defaultProps = {
        surplusSpace: true,
        pagination: true,
        pageSize: 10,
        pageNum: 1,
        serialText: "#",
    };

    state = {
        tableBodyHeight: 300,
        columns: [],
    };

    componentDidMount() {
        if (this.props.surplusSpace) {
            this.setTableBodyHeight();
            window.addEventListener("resize", this.setTableBodyHeight);
        }
        this.setState({
            columns: this.props.columns,
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.surplusSpace) {
            const prevDataSource = prevProps.dataSource;
            const { dataSource } = this.props;

            if (dataSource?.length !== prevDataSource?.length) {
                this.setTableBodyHeight();
            }
        }
    }

    componentWillUnmount() {
        if (this.props.surplusSpace) {
            window.removeEventListener("resize", this.setTableBodyHeight);
        }
    }

    setTableBodyHeight = () => {
        this.tableBody = this.wrapper.querySelector(".ant-table-body");
        this.tablePlaceholder = this.wrapper.querySelector(
            ".ant-table-placeholder"
        );
        this.tableHead = this.wrapper.querySelector(".ant-table-thead");

        const { pathname, search } = window.location;
        const currentPath = window.decodeURIComponent(`${pathname}${search}`);
        const activeTab = document.getElementById(currentPath);
        this.pagination = (activeTab ? activeTab : document).querySelector(
            ".pagination-wrap"
        );

        let tableBodyHeight;
        const { dataSource } = this.props;
        const windowHeight = document.documentElement.clientHeight;

        // 计算除了表格内容之外，其他高度
        if ("otherHeight" in this.props) {
            const { otherHeight } = this.props;
            tableBodyHeight = windowHeight - otherHeight;
        } else {
            const tableHeadHeight = this.tableHead.offsetHeight + 1;
            const paginationHeight = this.pagination
                ? this.pagination.offsetHeight + 8
                : 0;
            const bottomHeight = paginationHeight + 10 + 10;

            const tableOffsetTop = getElementTop(this.wrapper);
            const otherHeight = tableOffsetTop + tableHeadHeight + bottomHeight;

            tableBodyHeight = windowHeight - otherHeight - 21;
        }

        if (dataSource?.length) {
            this.tableBody.style.height = `${tableBodyHeight}px`;
        } else {
            this.tableBody.style.height = "0px";
            this.tablePlaceholder.style.height = `${tableBodyHeight}px`;
        }

        this.setState({ tableBodyHeight });
    };
    components = {
        header: {
            cell: ResizeableTitle,
        },
    };
    handleResize = (index) => (e, { size }) => {
        e.stopImmediatePropagation();
        this.setState(({ columns }) => {
            const nextColumns = [...columns];
            nextColumns[index] = {
                ...nextColumns[index],
                width: size.width,
            };
            return { columns: nextColumns };
        });
    };
    render() {
        let {
            scroll = {},
            pagination,
            surplusSpace,
            serialNumber,
            serialText,
            // 分页属性

            size,
            showSizeChanger,
            showQuickJumper,
            showMessage,
            pageSize,
            pageNum,
            total,
            onPageNumChange,
            onPageSizeChange,

            rowSelection,
            ...others
        } = this.props;
        const { tableBodyHeight } = this.state;
        let tableScroll = surplusSpace
            ? { y: tableBodyHeight, ...scroll }
            : scroll;

        if (rowSelection === true) rowSelection = {};

        if (!rowSelection) rowSelection = void 0;
        let columns = this.state.columns.map((col, index) => ({
            ...col,
            onHeaderCell: (column) => ({
                width: column.width ? column.width : 100,
                onResize: this.handleResize(index),
            }),
        }));
        if (serialNumber) {
            columns = [
                {
                    title: serialText,
                    width: 70,
                    dataIndex: "__num",
                    key: "__num",
                    render: (value, record, index) =>
                        index + 1 + pageSize * (pageNum - 1),
                },
                ...columns,
            ];
        }

        return (
            <div ref={(node) => (this.wrapper = node)}>
                <Table
                    scroll={tableScroll}
                    pagination={false}
                    rowSelection={rowSelection}
                    {...others}
                    columns={columns}
                    bordered
                    components={this.components}
                />
                {pagination ? (
                    <Pagination
                        size={size}
                        showSizeChanger={showSizeChanger}
                        showQuickJumper={showQuickJumper}
                        showMessage={showMessage}
                        pageSize={pageSize}
                        pageNum={pageNum}
                        total={total}
                        onPageNumChange={onPageNumChange}
                        onPageSizeChange={onPageSizeChange}
                    />
                ) : null}
            </div>
        );
    }
}
