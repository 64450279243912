// 此文件是通过脚本生成的，直接编辑无效！！！

// 不需要导航框架的页面路径
export const noFrames = [
    '/login1',
    '/login',
];

// 不需要登录就可以访问的页面路径
export const noAuths = [
    '/login1',
    '/login',
];

// 需要keep alive 页面
export const keepAlives = [
    {
        path: '/',
        keepAlive: false,
    },
    {
        path: '/iframe_page_/:src',
        keepAlive: true,
    },
    {
        path: '/login1',
        keepAlive: false,
    },
    {
        path: '/login',
        keepAlive: false,
    },
];

// 页面路由配置
export default [
    {
        path: '/example/ajax',
        component: () => import('E:/uni星球/unifamily-new/unifamily/doctormanager/src/pages/examples/ajax/index.jsx'),
    },
    {
        path: '/example/customer-header',
        component: () => import('E:/uni星球/unifamily-new/unifamily/doctormanager/src/pages/examples/customer-header/index.jsx'),
    },
    {
        path: '/',
        component: () => import('E:/uni星球/unifamily-new/unifamily/doctormanager/src/pages/home/index.jsx'),
    },
    {
        path: '/iframe_page_/:src',
        component: () => import('E:/uni星球/unifamily-new/unifamily/doctormanager/src/pages/iframe/index.jsx'),
    },
    {
        path: '/funtional-module-manager',
        component: () => import('E:/uni星球/unifamily-new/unifamily/doctormanager/src/pages/info-settings/functional/module-manager.jsx'),
    },
    {
        path: '/meeting-manager',
        component: () => import('E:/uni星球/unifamily-new/unifamily/doctormanager/src/pages/info-settings/meeting/meeting-manager.jsx'),
    },
    {
        path: '/user-info-manager',
        component: () => import('E:/uni星球/unifamily-new/unifamily/doctormanager/src/pages/info-settings/users/infoManager.jsx'),
    },
    {
        path: '/login1',
        component: () => import('E:/uni星球/unifamily-new/unifamily/doctormanager/src/pages/login/index.jsx'),
    },
    {
        path: '/login',
        component: () => import('E:/uni星球/unifamily-new/unifamily/doctormanager/src/pages/login1/index.jsx'),
    },
    {
        path: '/menu-permission',
        component: () => import('E:/uni星球/unifamily-new/unifamily/doctormanager/src/pages/menu-permission/index.jsx'),
    },
    {
        path: '/order-manager',
        component: () => import('E:/uni星球/unifamily-new/unifamily/doctormanager/src/pages/order-manager/index.jsx'),
    },
    {
        path: '/organ',
        component: () => import('E:/uni星球/unifamily-new/unifamily/doctormanager/src/pages/organ-infos/organ-infos.jsx'),
    },
    {
        path: '/package-list',
        component: () => import('E:/uni星球/unifamily-new/unifamily/doctormanager/src/pages/package-management/package-list/index.jsx'),
    },
    {
        path: '/package-review/_/edit/:id',
        component: () => import('E:/uni星球/unifamily-new/unifamily/doctormanager/src/pages/package-management/package-review/Edit.jsx'),
    },
    {
        path: '/package-review',
        component: () => import('E:/uni星球/unifamily-new/unifamily/doctormanager/src/pages/package-management/package-review/index.jsx'),
    },
    {
        path: '/package-money-collect',
        component: () => import('E:/uni星球/unifamily-new/unifamily/doctormanager/src/pages/package-money-collect/index.jsx'),
    },
    {
        path: '/package-order-manager',
        component: () => import('E:/uni星球/unifamily-new/unifamily/doctormanager/src/pages/package-order-manager/index.jsx'),
    },
    {
        path: '/settings',
        component: () => import('E:/uni星球/unifamily-new/unifamily/doctormanager/src/pages/setting/index.jsx'),
    },
    {
        path: '/user',
        component: () => import('E:/uni星球/unifamily-new/unifamily/doctormanager/src/pages/user-infos/user-infos.jsx'),
    },
    {
        path: '/withdrawal-management',
        component: () => import('E:/uni星球/unifamily-new/unifamily/doctormanager/src/pages/withdrawal-management/index.jsx'),
    },
];
